<script setup lang="ts">
import SmallContentLayout from '@/components/layout/SmallContentLayout.vue'
import { useGeneralStore } from '@/stores/GeneralStore'
import { useWizardStore } from '@/stores/WizardStore'
import { useEventListener } from '@vueuse/core'
import { onMounted, ref } from 'vue'

const generalStore = useGeneralStore()
const wizardStore = useWizardStore()
const scrollRef = ref<HTMLDivElement>()

useEventListener(scrollRef, 'scroll', (e: Event) => {
  if (e.target) {
    generalStore.setScrollY((e.target as HTMLDivElement).scrollTop)
  }
})

onMounted(() => {
  generalStore.setScrollY(0)
})
</script>

<template>
  <div class="pt-wizardHeaderWithStepsHeight desktop:pt-wizardHeaderHeight">
    <div
      :class="[
        'text-left overflow-y-auto flex flex-col w-full pb-80',
        wizardStore.showStepIndicator
          ? 'h-[calc(100dvh-theme(spacing.wizardHeaderWithStepsHeight)-theme(spacing.footerHeight))]'
          : 'h-[calc(100dvh-theme(spacing.wizardHeaderHeight)-theme(spacing.footerHeight))]',
      ]"
      ref="scrollRef"
    >
      <SmallContentLayout
        class="desktop:px-5"
        default-background="bg-neutral50"
      >
        <slot></slot>
      </SmallContentLayout>
    </div>
  </div>
</template>
